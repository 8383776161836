import React, { useState, useEffect } from "react";
import "./Endscreen.scss";
import SimpleSlider from "./Carousel";
import { PageHeader } from "@/components/header/header";
import Footer from "../Footer/Footer";
import Cookies from "js-cookie";
import catalogBg from "@/assets/ebook-1.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Endscreen = () => {
  /* Guide can't access residence/host's end screen page */
  if (Cookies.get("token")) {
    window.location.href = "/";
  }
  const catalogPdfURL: string | undefined = process.env.REACT_APP_CATLOG_URL;

  const handleButtonClick = (url: string) => {
    const newWindow = window.open(
      "about:blank",
      "_blank",
      "toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600"
    );

    if (newWindow) {
      newWindow.document.write(`
        <html>
          <head>
            <title>Redirecting...</title>
            <style>
              body {
                background-color: black;
              }
            </style>
          </head>
          <body>
            <script>
              window.location.href = "${url}";
            </script>
          </body>
        </html>
      `);
    } else {
      window.location.href = url;
    }
  };
  return (
    <div className="endscreen">
      <PageHeader ShowOffer={true} Showcontact={true} />
      <div className="endscreen-container">
        <div className="endscreen-left">
          <div className="endscreen-header">
            Votre voyage culturel est terminé.
          </div>
          <div className="endscreen-text">A très bientôt </div>
        </div>
        <div className="endscreen-right">
          <img
            className="endscreen-catalog-image"
            src={catalogBg}
            alt="catalog-image"
          />
          <div className="endscreen-catalog-text">
            Découvrez toutes les visites Travel Me
            <button
              className="endscreen-button"
              onClick={() => catalogPdfURL && handleButtonClick(catalogPdfURL)}
            >
              Voir le catalogue
              <ArrowForwardIosIcon />
            </button>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <SimpleSlider />
      </div>
      <Footer />
    </div>
  );
};
export default Endscreen;
