import { eventRoomStore } from "@/stores/event";
import AgoraRTMClient, { ChatCmdType } from "@/utils/agora-rtm-client";
import { jsonParse } from "@/utils/helper";
import { AgoraWebClient } from "@/utils/agora-web-client";
import { toast } from "react-toastify";

export default function useSubscribeRTMEvent() {
  const role = eventRoomStore.state.me.role;
  const subscribeRTMEvent = (rtmClient: AgoraRTMClient) => {
    rtmClient.on("error", (evt: any) => {});
    rtmClient.on("MemberCountUpdated", (count: number) => {
      eventRoomStore.updateViewerCount(count);
      if (role === 1) {
        eventRoomStore.getMembersList(eventRoomStore.state.me.channelName);
      }
    });
    rtmClient.on("MemberJoined", (memberId: string) => {
      console.log("Hello MemberId", memberId);
      // const inviteeId = eventRoomStore._state.me.info.invitees.find(
      //   (i: any) => i.id === memberId
      // );
      // console.log(inviteeId, eventRoomStore._state.me.info.invitees, "test1");
      // if (inviteeId) {
      //   console.log("test2");
      //   const details = {
      //     name: eventRoomStore.getUserNameById(inviteeId.id),
      //   };
      //   const userRole = eventRoomStore.getUserRoleById(inviteeId.id);
      // eventRoomStore.addInviteeUserDetails(inviteeId.id, details, userRole);
      // console.log(memberId, "dkvbdkvb");
      // }
      // eventRoomStore.addInviteeUserDetails(memberId);
    });

    rtmClient.on("MemberLeft", (memberId: string) => {
      console.log(memberId, "dkvbdkvb");
    });

    rtmClient.on(
      "ChannelMessage",
      ({
        memberId,
        message,
      }: {
        message: { text: string };
        memberId: string;
      }) => {
        console.log("Channel Message Received:", message.text);
        const { cmd, data } = jsonParse(message.text);
        if (cmd === ChatCmdType.chat) {
          const chatMessage = {
            text: data.text,
            ts: +Date.now(),
            id: memberId,
            name: data.name,
            profile_pic: data.profile_pic,
          };
          eventRoomStore.updateChatMessage(chatMessage);
          if (eventRoomStore._state.me.info.role !== "invitee") {
            toast.success("New Public Message Received!", {
              position: "bottom-right",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }

        if (cmd === ChatCmdType.muteAudio) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "audio");
        }
        if (cmd === ChatCmdType.unmuteAudio) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "audio"
          );
        }
        if (cmd === ChatCmdType.muteVideo) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "video");
        }
        if (cmd === ChatCmdType.unmuteVideo) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "video"
          );
        }
        if (cmd === ChatCmdType.pinid) {
          eventRoomStore.setPinId(data.streamID);
        }
      }
    );

    rtmClient.on(
      "MessageFromPeer",
      ({ peerId, message }: { message: { text: string }; peerId: string }) => {
        const { cmd, data, msgData } = jsonParse(message.text);

        if (cmd === ChatCmdType.chat) {
          const chatMessage = {
            text: data.text,
            ts: +Date.now(),
            id: peerId,
            name: data.name,
            profile_pic: data.profile_pic,
          };
          eventRoomStore.updatePrivateChatMessage(chatMessage);
          toast.success("New Private Message Received!", {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        if (cmd === ChatCmdType.muteAudio) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "audio");
        }
        if (cmd === ChatCmdType.unmuteAudio) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "audio"
          );
        }
        if (cmd === ChatCmdType.muteVideo) {
          eventRoomStore.mute(eventRoomStore.state.me.uid.toString(), "video");
        }
        if (cmd === ChatCmdType.unmuteVideo) {
          eventRoomStore.unmute(
            eventRoomStore.state.me.uid.toString(),
            "video"
          );
        }
        if (cmd === ChatCmdType.isSilent) {
          console.log("Is Silent Feature Received");
        }
        if (cmd === ChatCmdType.remoteUsersRemove) {
          sessionStorage.removeItem("visitStarted");
          window.location.href = "/end";
        }
      }
    );
  };
  return {
    subscribeRTMEvent,
  };
}
