import React from "react";
import "./show-talk-bottom-options.scss";
import { useEventRoomState } from "@/containers/root-container";

export default function ShowTalkBottomOptions({ children }: any) {
  const eventRoomState = useEventRoomState();

  // Determine base class name
  let baseClass = 'streambottomicons';

  // Apply conditional classes based on role and is_multiconnection
  if (eventRoomState.me.info.role === "invitee") {
    baseClass += ' invitees';
  }
  if (eventRoomState.me.info.is_multiconnection) {
    baseClass += ' multiconnection';
  }

  return (
    <div className={baseClass}>
      {children}
    </div>
  );
}
