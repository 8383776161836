import React from "react";
import { Route, Switch } from "react-router-dom";
import CustomBrowserRouter from "../containers/custom-browser-router";
import { EventPage } from "./event-page";
import PreShowTalk from "./event-page/preshow/pre-show";
import Loading from "../components/loading/loading";
import { RootProvider } from "../containers/root-container";
import { PageNotFound } from "./404";
import { ToastContainer } from "react-toastify";
import { eventRoomStore } from "@/stores/event";
import Endscreen from "./EndScreen/Endscreen";
import LoginForm from "./Login/LoginForm";
import GuideVisits from "./Guide/Visits";
import "react-toastify/dist/ReactToastify.css";
import Visit from "./Visit/";
import GuideEndScreen from "./Guide/EndScreen";
import Error from "../components/error";
import NativeLoader from "./Guide/NativeLoader";
import PrivacyPolicy from "./privacy-policy/privacy-policy";
import { HostDashBoard } from "./host-dashboard/HostDashBoard";

export default function () {
  const handleAutoPlayStatus = () => {
    eventRoomStore.updateAutoPlayFailedStatus(false);
  };

  return (
    <CustomBrowserRouter>
      <div onClick={handleAutoPlayStatus}>
        <ToastContainer autoClose={2000} hideProgressBar={false} />
      </div>
      <RootProvider>
        <Loading />
        <Switch>
          <Route exact path="/">
            <LoginForm />
          </Route>
          <Route exact path="/404">
            <PageNotFound />
          </Route>
          <Route exact path="/visit/:visit">
            <Visit />
          </Route>
          <Route exact path="/meeting/:meetingId">
            <EventPage>
              <PreShowTalk />
            </EventPage>
          </Route>
          <Route exact path="/visits">
            <GuideVisits />
          </Route>
          <Route exact path="/end-visit">
            <GuideEndScreen />
          </Route>
          <Route exact path="/error/">
            <Error heading="Error" message="Sorry, something went wrong." />
          </Route>
          <Route exact path="/error/access-denied">
            <Error
              heading="Access Denied"
              message="Sorry, you don't have access to current visit."
            />
          </Route>
          <Route exact path="/error/invalid">
            <Error
              heading="Invalid"
              message="Sorry, the visit, you are looking for has been ended."
            />
          </Route>
          <Route exact path="/end">
            <Endscreen />
          </Route>
          <Route exact path="/handlenative/:meetingId">
            <NativeLoader />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/visits/:userId">
            <HostDashBoard />
          </Route>
          <Route exact path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </RootProvider>
    </CustomBrowserRouter>
  );
}
