import React, { useEffect, useRef, useState } from "react";
import "./HostDashBoard.scss";
import { PageHeader } from "@/components/header/header";
import Footer from "../Footer/Footer";
import { DashBoardLayout } from "./dashboard-layout/DashBoardLayout";
import { useParams } from "react-router-dom";
import { eventRoomStore } from "@/stores/event";
import { toast } from "react-toastify";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const HostDashBoard = () => {
  const params = useParams<any>();
  const UserId = params.userId.replace("&limit=4&page=1", "");
  const isLoader = useRef<boolean>(true);
  const [eventDetailsData, setEventDetailsData] = useState<any[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(4);
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const getVisitDetails = async (page: number) => {
    try {
      const visitDetails: any = await eventRoomStore.fetchadminvisitDetails(
        UserId,
        itemsPerPage,
        page
      );
      if (visitDetails.status === 200) {
        setTotalPosts(visitDetails.total);
        isLoader.current = false;
        setEventDetailsData(visitDetails.visit);

        // Filtering the events to match the current date
        const today = new Date();
        const formattedToday = today.toISOString().split('T')[0]; // YYYY-MM-DD format
        const filtered = visitDetails.visit.filter((event: any) => {
          const eventDate = new Date(event?.data?.time_in_utc).toISOString().split('T')[0];
          return eventDate === formattedToday;
        });
        setFilteredEvents(filtered);
        setError(null);
      } else {
        throw new Error("No Visit Details found");
      }
    } catch (err) {
      setError("No Visit Details found");
      toast.error("No Visit Details found");
    }
  };

  useEffect(() => {
    const savedPage = sessionStorage.getItem('currentpage');
    if (savedPage) {
      setCurrentPage(parseInt(savedPage, 10));
    }
  }, []);

  useEffect(() => {
    getVisitDetails(currentPage);
    sessionStorage.setItem('currentpage', currentPage.toString());
  }, [currentPage, itemsPerPage]);

  const totalPages = Math.ceil(totalPosts / itemsPerPage);

  const pageHandler = (type: "forward" | "backward") => {
    if (type === "forward" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
    if (type === "backward" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="host-dashboard-page">
      <PageHeader ShowOffer={true} Showcontact={true} />
      {error && <div>{error}</div>}
      <div className="dashboard-events">
        {isLoader.current ? (
          <WelcomeLoader />
        ) : (
          <div className="dashboard-events-content">
            {filteredEvents.length > 0 ? (
              <>
                {filteredEvents.map((event: any, index: any) => (
                  <DashBoardLayout key={index} data={event?.data} />
                ))}
                {totalPosts > itemsPerPage && (
                  <div className="pagination">
                    {currentPage > 1 && (
                      <button
                        onClick={() => pageHandler("backward")}
                      >
                        <ArrowBackIcon />
                      </button>
                    )}
                    {currentPage < totalPages && (
                      <button
                        onClick={() => pageHandler("forward")}
                      >
                        <ArrowForwardIcon />
                      </button>
                    )}
                  </div>
                )}
              </>
            ) : (
              <div className="dashboard-events-content-error">
                No Visit is Scheduled right now
                {totalPosts > itemsPerPage && (
                  <div className="pagination">
                    {currentPage > 1 && (
                      <button
                        onClick={() => pageHandler("backward")}
                      >
                        <ArrowBackIcon />
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};
