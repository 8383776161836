import React, { useState } from "react";
import "./exit-control.scss";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useEventRoomState } from "@/containers/root-container";

interface ExitControlProps {
  handlePopUp: any;
}

const ExitControl: React.FC<ExitControlProps> = ({
  handlePopUp,
}) => {
  const handleExit = () => {
    handlePopUp();
  };
  const eventRoomState = useEventRoomState();

  return (
    <>
      <span className="stream-control end-call">
        <span
          className="exit stream-control-container end-button-container"
          style={{
            width: `${eventRoomState.me.info.role == "invitee" && "unset"}`,
          }}
          onClick={handleExit}
        >
          <span className="end-button_icon stream-control-primary_icon_exit ">
            <PowerSettingsNewIcon />
          </span>
        </span>
        {eventRoomState.me.info.role == "guide" ? (
          <p className="icons-tip">Exit</p>
        ) : (
          <p className="icons-tip">sortie</p>
        )}
      </span>
    </>
  );
};

export default React.memo(ExitControl);
