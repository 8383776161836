import React, { useState, useRef } from "react";
import "./chat-menu.scss";
import { CommentSection } from "@/components/comment-section/comment-section";
import ChatMessage from "@/components/chat-message/chat-message";
import { useEventRoomState } from "@/containers/root-container";

export default function ChatMenu(props: any) {
  const eventRoomState = useEventRoomState();
  const isGuideOrHost =
    eventRoomState.me.info.role === "guide" ||
    eventRoomState.me.info.role === "host";
  const isResidence =
    eventRoomState.me.info.role === "residence" ||
    eventRoomState.me.info.role === "multiconnection";
  const [activeTab, setActiveTab] = useState("messages");

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="chat-menu">
        <div
          className={`item ${
            activeTab === "messages" ? "chat-menu-active" : ""
          }`}
          onClick={() => handleTabChange("messages")}
        >
          {isResidence ? "Chat" : "All"}
        </div>
        {isGuideOrHost && (
          <div
            className={`item ${
              activeTab === "privateMessages" ? "chat-menu-active" : ""
            }`}
            onClick={() => handleTabChange("privateMessages")}
          >
            {eventRoomState.me.info.role === "host" ? "Guide" : "Host"}
          </div>
        )}
      </div>
      {activeTab === "messages" && (
        <>
          <ChatMessage publicOnly={true} />
          <CommentSection publicOnly={true} />
        </>
      )}
      {activeTab === "privateMessages" && (
        <>
          <ChatMessage publicOnly={false} />
          {eventRoomState.me.info.role === "host" && (
            <CommentSection publicOnly={false} />
          )}
        </>
      )}
    </>
  );
}
