import React from "react";
import Logo from "@/assets/logo.png";
import "./header.scss";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { eventRoomStore } from "@/stores/event";
import LogoutIcon from "@mui/icons-material/Logout";
import LogOutImage from "@/assets/logout.svg";

const HeaderGuide = (props: any) => {
  const history = useHistory();
  React.useEffect(() => {
    eventRoomStore.setHistory(history);
  }, [history]);

  return (
    <div className="header-guide">
      <div className="header-guide-logo">
        <img src={Logo} />
      </div>
      {props.showLogout && (
        <div>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              flexDirection: "column",
              backgroundColor: "transparent",
              border: 0,
              outline: "none",
              padding: 0,
              color: "#D46C6A",
            }}
            onClick={eventRoomStore.logout}
            className="header-guide-logout"
          >
            {/* <LogoutIcon /> */}
            <img src={LogOutImage} alt="logout-icon" />
            LOGOUT
          </button>
        </div>
      )}
    </div>
  );
};
export default HeaderGuide;
