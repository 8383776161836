import React, { useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import SilentResidenceControl from "../silent-residence-control/silent-residence-control";
import "./settings-options.scss";
import NoiseCancellation from "../noise-cancellation/noise-cancellation";

const SettingsOptions = () => {
  const [OpenOptions, setOpenOptions] = useState(false);
  const toogleHandler = () => {
    setOpenOptions(!OpenOptions);
  };
  const HostSettingHandler = () => {};
  return (
    <div>
      {OpenOptions && (
        <div className="settings-control-options">
          <SilentResidenceControl />
          <NoiseCancellation />
        </div>
      )}
      <span className="settings-control">
        <span className="settings-control-icon">
          <span className="settings-control-position">
            <SettingsIcon onClick={toogleHandler} />
          </span>
          <p className="icons-tip">Settings</p>
        </span>
      </span>
    </div>
  );
};

export default SettingsOptions;
