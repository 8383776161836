import React, { useEffect, useState } from "react";
import Logo from "@/assets/logo.png";
import "./LoginForm.scss";
import { Redirect, useHistory } from "react-router-dom";
import Axios from "axios";
import { ApiUrls } from "@/utils/api/constant/api.constant";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";

const LoginForm = () => {
  const token = Cookies.get("token");
  if (token) {
    return <Redirect to="/visits" />;
  }
  const [isloading, setisloading] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const username = event.target.username.value;
    const password = event.target.password.value;
    setisloading(true);
    Axios.post(ApiUrls.loginUrl, { username, password })
      .then((response) => {
        if (response.data.token) {
          Cookies.set("token", response.data.token, {
            expires: 10,
            sameSite: "strict",
          });
          history.push("/visits");
        }
      })
      .catch((error) => {
        setisloading(false);
        console.log(error);
        toast.error(error?.response.data.error || error?.response.data.message);
      });
  };
  const history = useHistory();
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <div className="login">
        {isloading ? (
          <WelcomeLoader />
        ) : (
          <div className="login-container">
            <div>
              <img src={Logo} />
            </div>
            <p className="login-container-title"> BIENVENUE</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="login-text">Enter your credentials</div>
              <label>Username</label>
              <input
                className="login-form-input"
                placeholder="username"
                name="username"
                type="email"
                id="user-text-field"
                autoComplete="username"
              />
              <label>Password</label>
              <input
                id="password-text-field"
                placeholder="password"
                className="login-form-input"
                type="password"
                autoComplete="current-password"
                name="password"
              />
              <button className="login-form-button" type="submit">
                LOGIN
              </button>
            </form>
            <footer>
              <div className="login-footer">
                &copy; COPYRIGHT - {year} -
                <a href="https://travelme-paris.com/">Travel-Me</a>
              </div>
              <div className="login-footer-term">
                By clicking Login , you agree to our
                <br></br>
                <div className="login-footer-term-link">
                  <a href="https://travelme-paris.com/en/terms-of-sales/">
                    Terms and Condition
                  </a>
                </div>
              </div>
            </footer>
          </div>
        )}
      </div>
    </>
  );
};

export default LoginForm;
