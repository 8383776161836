import React, { useState, useEffect, useRef } from "react";
import AudienceAvatar from "@/assets/audience-avatar.png";
import "./chat-message.scss";
import { useEventRoomState } from "@/containers/root-container";

export default function ChatMessage(props: any) {
  const [showChats, setShowChats] = useState(false);
  const eventRoomState = useEventRoomState();
  const chatOptionHover: any = React.useRef(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [comments, setComments] = React.useState([
    {
      id: "",
      text: "",
      name: "",
      profile_pic: "",
    },
  ]);

  const hasLink = (text: any) => {
    if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(text)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkLinkMsgView = (text: any) => {
    if (text == "") return;
    text = text.trim();
    let msgArr = text.split(" ");
    return msgArr.map((val: any, i: any) =>
      hasLink(val) ? (
        <a
          key={i}
          href={
            text.indexOf("http://") === 0 || text.indexOf("https://") === 0
              ? text
              : "//" + text
          }
          target="_blank"
        >
          {`  ${val}  `}
        </a>
      ) : (
        <span key={i}>{val} </span>
      )
    );
  };

  const handleHoverOff = (eventFrom = "chatButton") => {
    if (eventFrom === "chatButton") {
      setTimeout(() => setShowChats(chatOptionHover.current), 300);
    } else {
      setShowChats(false);
    }
    chatOptionHover.current = false;
  };

  const handleHoverOn = () => {
    chatOptionHover.current = true;
  };

  useEffect(() => {
    if (messagesEndRef) {
      messagesEndRef.current!.addEventListener(
        "DOMNodeInserted",
        (event: any) => {
          const { currentTarget: target } = event;
          target.scroll({ top: target.scrollHeight, behavior: "smooth" });
        }
      );
    }
  }, []);

  useEffect(() => {
    let msgList: any = [];
    let msgListPrivate: any = [];
    if (props.publicOnly) {
      eventRoomState.messages.forEach((data) => {
        msgList.push(data);
      });
      setComments(msgList);
    } else {
      eventRoomState.privateMessages.forEach((data) => {
        msgListPrivate.push(data);
      });
      setComments(msgListPrivate);
    }
    // handleScrollToBottom()
  }, [
    eventRoomState.messages,
    eventRoomState.privateMessages,
    props.publicOnly,
  ]);

  return (
    <div className="chat-input-section" onMouseLeave={() => handleHoverOff()}>
      <div
        className="chat-icon-content"
        ref={messagesEndRef}
        id="chat-icon-content"
        onMouseLeave={() => handleHoverOff("chat-options")}
        onMouseEnter={handleHoverOn}
      >
        {comments.map((value, index) => (
          <div
            className={
              value.id == eventRoomState.me.uid.toString()
                ? "users-comment sent"
                : "users-comment recieved"
            }
            key={index}
          >
            {value.id == eventRoomState.me.info.uid.toString() ? (
              eventRoomState.me.info.profile_pic == "" ? (
                <img src={AudienceAvatar} />
              ) : (
                <img src={eventRoomState.me.info.profile_pic} />
              )
            ) : value.profile_pic == "" ? (
              <img src={AudienceAvatar} />
            ) : (
              <img src={value.profile_pic} />
            )}
            <div className="_info">
              <label>{value.name}</label>
              <p>{checkLinkMsgView(value.text)}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}