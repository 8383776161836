export const APP_ID = process.env.REACT_APP_AGORA_APP_ID as string;
export const APP_TOKEN = process.env.REACT_APP_AGORA_APP_TOKEN as string;
export const ENABLE_LOG = process.env.REACT_APP_AGORA_LOG as string;
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || window.location.origin;

export const MainLiveStreamId = 33333333;
export const ADMIN_UID = 44444444;
export const MAX_USERS_STREAMS = 2;
export const INJECT_STREAM_ID = 99999999;
export const SECOND_INJECT_STREAM_ID = 99999998;
export const SCREEN_SHARE_STREAM_ID = 999999997;

export const SHOW_TIMER_NOTIFICATION: any = {
  TIME: 300, //In Seconds
  MESSAGE: "Your class will end in 5 mins",
  TYPE: "Time",
};

export const QUERY_STRING_SEPARATOR = "@####@";
export const SCREEN_ALREADY_SHARED: any = {
  TYPE: "screen",
  MESSAGE: "You can not share the screen as a screen is already being shared",
};

export const EVENTS_DETAIL: any = {
  DEFAULT_TOAST_TIMEOUT: 60000,

  PERMISSION_BLOCKED_MSG:
    "Either Video/Audio Permissions are blocked. Please enable them!!",
  PERMISSION_TOAST_TYPE: "device_permission",
  AUTOPLAY_CLICK_TIMEOUT: 120000,
  AUTOPLAY_CLICK_TOAST_TYPE: "resume_audio",
  AUTOPLAY_TOAST_MSG: "Click here to resume audio!!",
  COPY_TIMEOUT: 30000,

  PRESHOW: {
    CHANNEL_NAME: "preshow",
    AUDIO_CHANNEL_NAME: "audio_preshow",
  },
  //AUDIENCE_INVITE_TEXT: "Congrats! You've been selected to join the %EVENT_NAME%"
};

export const USER_ROLES: any = {
  HOST: "host",
  AUDIENCE: "audience",
};

export const STREAMSLAYOUT: any = {
  streamLayout: {
    "3": [
      "span 6/span 24/7/25",
      "span 6/span 12/13/13",
      "span 6/span 12/13/25",
    ],
    "5": [
      "span 12/span 12/13/13",
      "span 6/span 6/7/19",
      "span 6/span 6/7/25",
      "span 6/span 6/13/19",
      "span 6/span 6/13/25",
    ],
    "7": [
      "span 12/span 8/13/9",
      "span 4/span 8/5/17",
      "span 4/span 8/5/25",
      "span 4/span 8/9/17",
      "span 4/span 8/9/25",
      "span 4/span 8/13/17",
      "span 4/span 8/13/25",
    ],
    "8": [
      "span 6/span 8/7/9",
      "span 6/span 8/13/9",
      "span 4/span 8/5/17",
      "span 4/span 8/5/25",
      "span 4/span 8/9/17",
      "span 4/span 8/9/25",
      "span 4/span 8/13/17",
      "span 4/span 8/13/25",
    ],
    "10": [
      "span 12/span 6",
      "span 4/span 6/5/13",
      "span 4/span 6/5/19",
      "span 4/span 6/5/25",
      "span 4/span 6/9/13",
      "span 4/span 6/9/19",
      "span 4/span 6/9/25",
      "span 4/span 6/13/13",
      "span 4/span 6/13/19",
      "span 4/span 6/13/25",
    ],
    "11": [
      "span 6/span 6/7/7",
      "span 6/span 6/13/7",
      "span 4/span 6/5/13",
      "span 4/span 6/5/19",
      "span 4/span 6/5/25",
      "span 4/span 6/9/13",
      "span 4/span 6/9/19",
      "span 4/span 6/9/25",
      "span 4/span 6/13/13",
      "span 4/span 6/13/19",
      "span 4/span 6/13/25",
    ],
    "13": [
      "span 12/span 6",
      "span 3/span 6/4/13",
      "span 3/span 6/4/19",
      "span 3/span 6/4/25",
      "span 3/span 6/7/13",
      "span 3/span 6/7/19",
      "span 3/span 6/7/25",
      "span 3/span 6/10/13",
      "span 3/span 6/10/19",
      "span 3/span 6/10/25",
      "span 3/span 6/13/13",
      "span 3/span 6/13/19",
      "span 3/span 6/13/25",
    ],
    "14": [
      "span 6/span 6/7/7",
      "span 6/span 6/13/7",
      "span 3/span 6/4/13",
      "span 3/span 6/4/19",
      "span 3/span 6/4/25",
      "span 3/span 6/7/13",
      "span 3/span 6/7/19",
      "span 3/span 6/7/25",
      "span 3/span 6/10/13",
      "span 3/span 6/10/19",
      "span 3/span 6/10/25",
      "span 3/span 6/13/13",
      "span 3/span 6/13/19",
      "span 3/span 6/13/25",
    ],
    "15": [
      "span 4/span 6/5/7",
      "span 4/span 6/9/7",
      "span 4/span 6/13/7",
      "span 3/span 6/4/13",
      "span 3/span 6/4/19",
      "span 3/span 6/4/25",
      "span 3/span 6/7/13",
      "span 3/span 6/7/19",
      "span 3/span 6/7/25",
      "span 3/span 6/10/13",
      "span 3/span 6/10/19",
      "span 3/span 6/10/25",
      "span 3/span 6/13/13",
      "span 3/span 6/13/19",
      "span 3/span 6/13/25",
    ],
  },
  streamsContainerLayout: {
    "1": "98%",
    "2": "98%",
    "4": "49%",
    "6": "32%",
    "9": "32%",
    "12": "32%",
    "16": "24%",
  },
};
