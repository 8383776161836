import { io } from "socket.io-client";
import { ApiUrls } from "@/utils/api/constant/api.constant";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import { eventRoomStore } from "@/stores/event";

let socket: any;

let pendingEmits: any[] = [];
let reconnectFunction: any;

const useSocket = () => {
  const initializeSocket = () => {
    console.log("initializeSocket called");
    if (!socket?.connected) {
      socket = io(`${ApiUrls.domain}`, {
        transports: ["websocket", "polling"],
      });
      removeSocketEvent(ESocketEvents.Connect);
      removeSocketEvent(ESocketEvents.Disconnect);
      socket.on(ESocketEvents.Connect, (data: any) => {
        if (pendingEmits.length > 0) {
          pendingEmits.forEach((ele: any) => {
            emitEvent(ele.eventType, ele.data);
          });
        }
        eventRoomStore._state.hasJoinedVisit = true;
        if (reconnectFunction) {
          reconnectFunction();
        }
        pendingEmits = [];
        reconnectFunction = undefined;
        console.log("SOCKET_INIT", socket.id, data);
      });
      socket.on(ESocketEvents.Disconnect, (data: any) => {
        if (data === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.connect();
        }
        console.log("SOCKET_Disconneted", socket.id, data, { ...socket });
      });
    }
  };

  const addSocketEvent = (
    eventType: ESocketEvents,
    eventFunction: (data: any) => void
  ) => {
    // if (socket?.connected) {
    removeSocketEvent(eventType);
    socket.on(eventType, (data: any) => {
      eventFunction(data);
      console.log("useSOCKET_ON", eventType, data);
    });
    console.log("SOCKET_ADDED", eventType);
  };

  const removeSocketEvent = (eventType: ESocketEvents) => {
    socket.removeAllListeners(eventType);
    console.log("useSOCKET_Off", eventType);
  };

  const emitEvent = (eventType: ESocketEvents, data?: any) => {
    if (socket?.connected) {
      socket.emit(eventType, data);
      console.log("useSOCKET_EMIT", eventType);
      console.log("socket", { ...socket });
      console.log("socket data", data, eventType);
    } else {
      console.log("useSOCKET_EMIT_Pushed", eventType, { ...socket });
      pendingEmits.push({ eventType, data });
    }
  };

  const addSocketDisconnectEvent = ({
    emitEventType,
    data,
    setReconnectFunction,
  }: {
    emitEventType?: ESocketEvents;
    data?: any;
    setReconnectFunction?: any;
  }) => {
    removeSocketEvent(ESocketEvents.Disconnect);

    socket.on(ESocketEvents.Disconnect, (disconnectData: any) => {
      if (emitEventType) {
        emitEvent(emitEventType, data);
      }
      if (setReconnectFunction) {
        reconnectFunction = setReconnectFunction;
      }
      if (disconnectData === "io server disconnect") {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
      eventRoomStore._state.hasJoinedVisit = false;
      console.log(
        "SOCKET_Custom_DISCONNECT",
        socket.id,
        disconnectData,
        emitEventType,
        data
      );
    });
  };
  return {
    initializeSocket,
    addSocketEvent,
    addSocketDisconnectEvent,
    removeSocketEvent,
    emitEvent,
  };
};

export default useSocket;
