import React, { useEffect, useRef, useState } from "react";
import "./visits.scss";
import Cookies from "js-cookie";
import { updateUserToken } from "@/utils/api/services/auth.services";
import { Redirect } from "react-router-dom";
import WelcomeLoader from "../../WelcomeLoader/WelcomeLoader";
import HeaderGuide from "../Header";
import { toast } from "react-toastify";
import { eventRoomStore } from "../../../stores/event";
import VisitTable from "./Table";

const GuideList = () => {
  const [visits, setvisits]: any = useState([]);
  const [isLoader, setisLoader] = useState(true);
  const [currentpage, setcurrentpage] = useState(1);
  const itemsPerPage = 4;
  const totalevents: any = useRef(0);
  const isTouchingDown = useRef(false);

  if (!Cookies.get("token")) {
    return <Redirect to="/" />;
  }

  const fetchGuidesVisitsList = async (
    currentpage: number,
    itemsPerPage: number
  ) => {
    try {
      const lists = await eventRoomStore.fetchGuideVisits(
        currentpage,
        itemsPerPage
      );
      if (lists.status == 200) {
        const visitData: any = lists.visits;
        if (currentpage == 1) {
          totalevents.current = visitData.total_visits;
        }
        setisLoader(false);
        setvisits(visitData.entries);
      } else if (lists.status == 401) {
        await updateUserToken(Cookies.get("token"));
        fetchGuidesVisitsList(currentpage, itemsPerPage);
      } else {
        eventRoomStore.logout();
      }
    } catch (error) {
      toast.error("Sorry, something went wrong!!");
    }
  };

  useEffect(() => {
    setisLoader(true);
    fetchGuidesVisitsList(currentpage, itemsPerPage);
  }, [currentpage]);

  useEffect(() => {
    let startY = 0;
  
    const handleTouchStart = (e: TouchEvent) => {
      startY = e.touches[0].clientY;
    };
  
    const handleTouchMove = (e: TouchEvent) => {
      const deltaY = e.touches[0].clientY - startY;
      // Adjust this threshold as needed to account for intentional scroll actions
      const scrollThreshold = 50;
  
      // Only prevent default behavior if scrolling up
      if (deltaY > 0) {
        e.preventDefault();
      }
  
      // If scrolled beyond threshold, trigger logout
      if (deltaY > scrollThreshold) {
        e.preventDefault();
      }
    };
  
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchmove", handleTouchMove);
  
    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);

  return (
    <div className="guide-listing">
      <HeaderGuide showLogout = {true} />
      {isLoader ? (
        <WelcomeLoader />
      ) : (
        <>
          <div className="guide-listing-table-height">
            {visits.length > 0 ? (
              <VisitTable
                visits={visits}
                itemsPerPage={itemsPerPage}
                totalevents={totalevents.current}
                currentpage={currentpage}
                setcurrentpage={setcurrentpage}
              />
            ) : (
              <div className="guide-listing-table-no-response">
                <div className="guide-listing-table-no-response-text">
                  No Visit Planned
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default GuideList;
