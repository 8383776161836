import React, { useState, useEffect, useRef } from "react";
import { eventRoomStore } from "@/stores/event";
import "./screen-share.scss";
import { useEventRoomState } from "@/containers/root-container";
import { SCREEN_SHARE_STREAM_ID } from "@/utils/config";
import useStream from "@/hooks/use-stream";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import { globalStore } from "@/stores/global";
import { SCREEN_ALREADY_SHARED } from "../../utils/config";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";

export default function ScreenShare(props: any) {
  const eventRoomState = useEventRoomState();
  const webClient = eventRoomStore.rtcClient as any;

  const { allStreams, guideStream } = useStream(eventRoomStore);

  const screenShared = (stream: any) => {
    return stream.some(
      (stream: any) => stream.streamID === SCREEN_SHARE_STREAM_ID
    ) as any;
  };

  const shareScreen = async () => {
    if (allStreams.length > 0 && !screenShared(allStreams)) {
      console.log("SCREEN_SHARE_STREAM_ID", SCREEN_SHARE_STREAM_ID);
      const token = await eventRoomStore.getAgoraTokens(
        eventRoomState.me.channelName,
        SCREEN_SHARE_STREAM_ID
      );
      const appId = eventRoomState.appID;
      const channel = eventRoomState.me.channelName;
      const uid = SCREEN_SHARE_STREAM_ID;
      await webClient.startScreenShare({ uid, channel, token, appId });
      eventRoomStore.setMeAttr("mainLiveStreamID", 0);
      eventRoomStore.setPinId(uid);
      eventRoomStore.handleRemotePin(uid);
      props.handleSwtichStream(uid);
    } else {
      globalStore.showToast({
        type: SCREEN_ALREADY_SHARED.TYPE,
        message: SCREEN_ALREADY_SHARED.MESSAGE,
        config: {
          hideProgressBar: true,
          closeOnClick: true,
          type: "dark",
          autoClose: 2500,
        },
      });
    }
  };

  const stopShare = () => {
    webClient.stopScreenShare();
  };

  return (
    <span className="screen-share-section stream-control">
      {eventRoomStore.rtcClient.shared ? (
        <>
          <span
            className="screen-share screen-share-button-container stream-control-container"
            onClick={stopShare}
          >
            <button className="screen-share-button-mic_icon stream-control-primary_icon">
              <StopScreenShareIcon />
            </button>
          </span>
          <p className="icons-tip">Stop Screen Share</p>
        </>
      ) : (
        <>
          <span
            className="screen-share screen-share-button-container stream-control-container"
            onClick={shareScreen}
          >
            <button className="screen-share-button-mic_icon stream-control-primary_icon">
              <ScreenShareIcon />
            </button>
          </span>
          <p className="icons-tip">Screen Share</p>
        </>
      )}
    </span>
  );
}
