import React, { useEffect } from "react";
import "./popup.scss";
import { useEventRoomState } from "@/containers/root-container";
import cancelBtn from "@/assets/cancel-btn.png";
import { eventRoomStore } from "@/stores/event";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import CloseIcon from "@mui/icons-material/Close";
export default function PopUp(props: any) {
  const { initializeSocket, emitEvent } = useSocket();
  const eventRoomState = useEventRoomState();
  const getStyle = () => {
    return {
      width: "42%",
    };
  };

  useEffect(() => {
    initializeSocket();
  }, []);

  const exit = (exitType: any) => {
    if (
      (eventRoomState.me.info.role == "host" && exitType == "quit") ||
      (eventRoomState.me.info.role == "guide" && exitType == "quit")
    ) {
      if (eventRoomState.me.info.role === "guide" && exitType == "quit") {
        emitEvent(ESocketEvents.GUIDE_LEAVE, {
          visit_id: eventRoomState.me.channelName,
        });
      } else if (eventRoomState.me.info.role === "host" && exitType == "quit") {
        emitEvent(ESocketEvents.EndVisit, {
          visit_id: eventRoomState.me.channelName,
        });
      }
    }
    let endScreenURL = "/end";
    if (eventRoomState.me.info.role == "guide") {
      endScreenURL = "/end-visit";
    }
    eventRoomStore.handleMainExit(eventRoomState.me.info.role, endScreenURL);
  };

  const handlemeetClose = (exitType: any) => {
    eventRoomStore.ExitFullScreenMode();
    eventRoomStore.exitAll();
    exit(exitType);
  };

  return (
    <div id="myModal" className="modal" onClick={props.handlePopUpClose}>
      <div
        className={`modal-content ${
          props.type != "cameraSetting" &&
          eventRoomState.me.info.role === "guide"
            ? "closePopup"
            : ""
        }`}
        style={getStyle()}
      >
        <>
          <div className="close" onClick={props.handlePopUpClose}>
            <CloseIcon onClick={props.handlePopUpClose} />
          </div>
          {eventRoomState.me.info.role != "guide" && (
            <div className="s-card-inner">
              <h5>
                Avant de partir… Etes-vous sûr de bien vouloir quitter la
                session?
              </h5>
              <div className="s-card-wrapper">
                <div className="s-card-info">
                  <div className="s-btn">
                    <button
                      className="s-btn-quit"
                      onClick={() => {
                        if (eventRoomState.me.info.role == "host") {
                          props.handlePopUpClose();
                          props.toggleConfirmationPopup();
                        } else {
                          handlemeetClose("quit");
                        }
                      }}
                    >
                      {eventRoomState.me.info.role == "host"
                        ? "Quitter et fermer"
                        : "Quitter"}
                    </button>
                    {eventRoomState.me.info.role == "host" && (
                      <button
                        onClick={handlemeetClose}
                        className="s-btn-quit s-btn-leave"
                      >
                        Juste partir
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {eventRoomState.me.info.role == "guide" && (
            <div className="s-card-inner">
              <h5>
                Before leaving… Are you sure you want to leave the session?
              </h5>
              <div className="s-card-wrapper">
                <div className="s-card-info">
                  <div className="s-btn">
                    <button
                      className="s-btn-quit"
                      onClick={() => handlemeetClose("quit")}
                    >
                      Leave and close
                    </button>
                    {eventRoomState.me.info.role == "guide" && (
                      <button
                        onClick={handlemeetClose}
                        className="s-btn-quit s-btn-leave"
                      >
                        Just leave
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
