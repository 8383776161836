import React from "react";
import Logo from "@/assets/logo.png";
import "./header.scss";
import { ApiUrls } from "@/utils/api/constant/api.constant";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface PageHeaderProps {
  ShowOffer?: Boolean;
  Showcontact?: Boolean;
  user_type?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  ShowOffer,
  Showcontact,
  user_type,
}) => {
  return (
    <div className="topbar">
      <div className="wrapper">
        <div className="header">
          <div
            onClick={() => {
              window.location.href = `${ApiUrls.TRAVEL_ME_URL}`;
            }}
            className="logo"
          >
            <img src={Logo} />
          </div>
          {ShowOffer && (
            <div
              onClick={() => {
                window.location.href =
                  "https://travelme-paris.com/senior-et-sante/";
              }}
              className="header-offer"
            >
              Voyage à distance au coeur du patrimoine français
            </div>
          )}
        </div>
        {Showcontact && (
          <div
            onClick={() => {
              window.location.href = `${ApiUrls.TRAVEL_ME_CONTACT}`;
            }}
            className={
              "header-contact-catalog"
              // user_type === "host" || user_type === "residence"
              //   ? "header-contact-catalog"
              // "header-contact"
            }
          >
            CONTACTEZ-NOUS
            <KeyboardArrowRightIcon />
          </div>
        )}
      </div>
    </div>
  );
};
