import React, { useState, useRef } from "react";
import { eventRoomStore } from "@/stores/event";
import "./error.scss";
import { useEventRoomState } from "@/containers/root-container";

const AccessAllowedError = () => {
  const eventRoomState = useEventRoomState();
  const pageRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="permission-ask-wrapper">
      <div className="permission-block">
        {eventRoomStore._state.me.info.role == "guide" ? (
          <p className="message">
            Please authorize your browser and reload the application When
            joining.
          </p>
        ) : (
          <p className="message">
            Veuillez autoriser le navigateur à accéder à vos périphériques audio
            et vidéo et recharger l’application
          </p>
        )}
        {eventRoomStore._state.me.info.role == "guide" ? (
          <div className="instruction">
            <p>1. Click on the lock icon on address bar.</p>
            <p>2. Allow access to camera, microphone and refresh the page.</p>
          </div>
        ) : (
          <div className="instruction">
            <p>
              1. Cliquez sur l'icône de verrouillage dans la barre d'adresse.
            </p>
            <p>
              2. Autoriser l'accès à la caméra, au microphone et actualiser la
              page.
            </p>
          </div>
        )}
        <button className="access-btn" onClick={pageRefresh}>
          {eventRoomState.me.info.role == "guide"
            ? "Refresh"
            : "Rafraichir votre page"}
        </button>
      </div>
    </div>
  );
};

export default AccessAllowedError;
