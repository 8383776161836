import React from "react";
import "./Error.scss";
import Logo from "@/assets/logo.png";
import { eventRoomStore } from "../../stores/event";
import Cookies from "js-cookie";

const Error = (props: any) => {
  const heading = props.heading || "Oops! Page Not Be Found";
  const message =
    props.message || "Sorry but the page you are looking for does not exist.";
  return (
    <>
      <div className="vertical-center">
        <div id="notfound" className="text-center">
          {/* <div className="error-logo">
            <img src={Logo} />
          </div> */}
          <h1>😮</h1>
          <h2>{heading}</h2>
          <p>{message}</p>
          {Cookies.get("token") ? (
            <a href="/visits">View you visits</a>
          ) : (
            <a href={`${process.env.REACT_APP_TRAVEL_ME_PARIS_URL}`}>
              Back to homepage
            </a>
          )}
        </div>
      </div>
    </>
  );
};
export default Error;
