import React, { useEffect, useState } from "react";
import "./welcome.scss";
import { useHistory } from "react-router-dom";
import { formatTime, formatDay } from "@/utils/helper";
import WelcomeLoader from "../../WelcomeLoader/WelcomeLoader";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import LoginForm from "../../Login/LoginForm";
import { eventRoomStore } from "@/stores/event";
import HeaderGuide from "../Header";
import { isAndroid, isIOS } from "react-device-detect";
import { globalStore } from "@/stores/global";
import MapContainer from "@/components/MapContainer";
import expandArrow from "@/assets/expand_arrow.png";
import collapseArrow from "@/assets/collapse_arrow.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const GuideWelcome = (props: any) => {
  const [isLoader, setisLoader] = useState(false);
  const [showInsideContainer, setShowInsideContainer] = useState(false);
  if (!Cookies.get("token")) {
    return <LoginForm />;
  }
  const history = useHistory();
  const visit = props?.visit_id;
  const [GuideDetails, setGuideDetails] = useState<any>(props?.visit);

  const joinVisit = (time: any) => {
    if (eventRoomStore.canStartVisit(time)) {
      if (isAndroid || isIOS) {
        history.push(`/handlenative/${visit}`);
      } else {
        history.push(`/meeting/${visit}`);
      }
    } else {
      toast.error("You are not Allowed to Join before 20 minutes");
    }
  };

  useEffect(() => {
    globalStore.stopLoading();
  }, []);

  // Delhi Coordinates
  const residenceLat = GuideDetails.residence_latitude;
  const residenceLng = GuideDetails.residence_longitude;

  // Gurgaon Coordinates
  const guideLat = GuideDetails.guide_latitude;
  const guideLng = GuideDetails.guide_longitude;

  return (
    <>
      <div className="guide-welcome">
        <HeaderGuide showLogout={false} />
        {isLoader ? (
          <WelcomeLoader />
        ) : (
          <div className="guide-welcome-content">
            <div className="guide-welcome-content-name">
              Hello {GuideDetails.guide_name}
              <button
                onClick={() => {
                  joinVisit(GuideDetails.time_in_utc);
                }}
                className="guide-welcome-content-button"
              >
                ENTER THE VISIT
              </button>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">Your visit</span>
              <div className="guide-welcome-content-header">
                {GuideDetails.visit_name}
              </div>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">Date</span>
              <div className="guide-welcome-content-header">
                {formatDay(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )}{" "}
                {/* /
                {formatTime(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )} */}
              </div>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">Hour</span>
              <div className="guide-welcome-content-header">
                {formatTime(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )}
              </div>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">With</span>
              <div className="guide-welcome-content-header">
                {GuideDetails.residence_name}
                {GuideDetails.new_visit && (
                  <span className="guide-welcome-content-header-new">New</span>
                )}
              </div>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">Type</span>
              <div className="guide-welcome-content-header">
                {` ${GuideDetails.residence_type}`}
              </div>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">Location</span>
              <div className="guide-welcome-content-header">
                {GuideDetails.city} ,{GuideDetails.postalCode}
              </div>
            </div>
            <div className="guide-welcome-list">
              <span className="list-name">Note</span>
              <div className="guide-welcome-content-header">
                {GuideDetails.details}
              </div>
            </div>
            <div className="inside-map-container">
              <MapContainer
                coordinates={{
                  residence: { lat: residenceLat, lng: residenceLng },
                  guide: { lat: guideLat, lng: guideLng },
                }}
                eventDetail={GuideDetails}
              />
            </div>
            {/* {showInsideContainer && (
            )} */}
            {/* <div className="guide-welcome-content-tour-place">
              {GuideDetails.visit_name}
            </div> */}
            {/* <div className="guide-welcome-content-time">
              <div>
                {formatDay(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )}{" "}
                /
                {formatTime(
                  eventRoomStore.convertTimeFromUTC(GuideDetails.time_in_utc)
                )}
              </div>
            </div>
            <div className="guide-welcome-content-residence">
              <span>Residence </span>
              {GuideDetails.residence_name}
              {` ${GuideDetails.residence_type}`}
              <div>
                {GuideDetails.city} ,{GuideDetails.postalCode}
              </div>
            </div> 
            <div
              style={{ marginTop: showInsideContainer ? "0" : "2.125rem" }}
              className="toggle-text"
              onClick={() => setShowInsideContainer(!showInsideContainer)}
            >
              {showInsideContainer ? (
                <span className="text-style-toogel">
                  Less Info
                  <KeyboardArrowUpIcon />
                </span>
              ) : (
                <span className="text-style-toogel">
                  More Info
                  <KeyboardArrowDownIcon />
                </span>
              )}
            </div> */}
          </div>
        )}
      </div>
    </>
  );
};
export default GuideWelcome;
