import Axios from "axios";
import Cookies from "js-cookie";
import { ApiUrls } from "../constant/api.constant";

export const updateUserToken = async (token: any) => {
  try {
    const res = await Axios.post(
      ApiUrls.refreshTokenUrl,
      {},
      {
        headers: { Authorization: token },
      }
    );
    if (res.data) {
      Cookies.set("token", res.data.token);
    }
    return res.data;
  } catch (e: any) {
    Cookies.remove("token");
    throw new Error(e?.message);
  }
};