import React, { useRef, useEffect, useState } from "react";
import "./video-player.scss";
import { useEventRoomState } from "@/containers/root-container";
import useStream from "@/hooks/use-stream";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import RemoteAudioControl from "@/components/remote-audio-control/remote-audio-control";
import RemoteVideoControl from "@/components/remote-video-control/remote-video-control";
import pinIcon from "@/assets/pin-icon.png";
import unPinIcon from "@/assets/unpin-icon.png";
import { eventRoomStore } from "@/stores/event";

interface VideoPlayerProps {
  domId?: string;
  id?: string;
  streamID: number;
  preview?: boolean;
  stream?: any;
  role?: string;
  audio: boolean;
  video: boolean;
  className?: string;
  showProfile?: boolean;
  local?: boolean;
  handleClick?: (type: string, streamID: number, uid: string) => Promise<any>;
  close?: boolean;
  handleClose?: (uid: string, streamID: number) => void;
  autoplay?: boolean;
  onAutoPlayFailed?: (stream: any) => void;
  mainStream?: boolean;
  name: any;
  mainSt?: any;
  index?: any;
  handleSwitch?: (streamID: number) => void;
  liveStreams?: any;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  preview,
  role,
  stream,
  className,
  domId,
  streamID,
  audio,
  id,
  video,
  handleClick,
  local,
  handleClose,
  close,
  autoplay,
  onAutoPlayFailed,
  mainStream,
  name,
  mainSt,
  index,
  handleSwitch,
  liveStreams = {},
}) => {
  const eventRoomState = useEventRoomState();
  const lockPlay = useRef<boolean>(false);
  const lockPlayAudio = useRef<boolean>(false);
  const { allStreams, adminStream, guideStream } = useStream(eventRoomStore);
  const videoFitPosition = useRef<string>("");
  useEffect(() => {
    //if (!streamID || (stream.videoTrack==null && stream.audioTrack==null) || (stream.videoTrack!=null && stream.videoTrack.isPlaying) || (stream.audioTrack!=null && stream.audioTrack.isPlaying)) return;
    if (!streamID) return;
    playStream();
    return () => {
      if (stream.videoTrack && stream.videoTrack.isPlaying && stream) {
        try {
          if (local) {
            stream.videoTrack.setEnabled(false); //Disable camera access when a user mutes their local video
          }
          stream.videoTrack.stop();
          lockPlay.current = false;
        } catch (e) {
          console.warn("Stop streaming Error", e);
        }
      }
    };
  }, [streamID, video]);

  useEffect(() => {
    if (!streamID) return;
    playStreamAudio();

    return () => {
      if (stream.audioTrack && stream.audioTrack.isPlaying && stream) {
        try {
          stream.audioTrack.stop();
          lockPlayAudio.current = false;
        } catch (e) {
          console.warn("Stop streaming Error", e);
        }
      }
    };
  }, [streamID, audio]);

  const playStreamAudio = () => {
    lockPlayAudio.current = true;
    if (
      typeof stream.audioTrack != "undefined" &&
      stream.audioTrack != null &&
      audio &&
      eventRoomState.me.uid !== streamID
    ) {
      if (!local && sessionStorage.getItem("speakerId")) {
        stream.audioTrack.setPlaybackDevice(
          sessionStorage.getItem("speakerId")
        );
      }
      stream.audioTrack.play();
      lockPlayAudio.current = true;
    }
  };

  const playStream = async () => {
    lockPlay.current = true;
    let isGuideStream = false;
    if (eventRoomState.me.info.role == "guide" && local) {
      isGuideStream = true;
    } else if (eventRoomState.me.info.role != "guide") {
      if (guideStream && guideStream?.streamID == streamID) {
        isGuideStream = true;
      }
    }

    const fit =
      isGuideStream && videoFitPosition.current
        ? videoFitPosition.current
        : isGuideStream && !videoFitPosition.current
        ? "contain"
        : "cover";

    if (
      typeof stream.videoTrack != "undefined" &&
      stream.videoTrack != null &&
      video
    ) {
      if (local) {
        await stream.videoTrack.setEnabled(true); //Enable camera access when a user mutes their local video
      }
      stream.videoTrack.play(`${streamID}`, { fit: fit }, (err: any) => {
        lockPlay.current = false;
        console.warn(
          "[video-player] ",
          JSON.stringify(err),
          id,
          stream.videoTrack.isPaused(),
          stream.videoTrack.isPlaying(),
          " isLocal: ",
          local
        );
      });

      if (isGuideStream) {
        const divElement = document.getElementById(`${streamID}`);
        const videoElement = divElement?.querySelector("video");

        videoElement?.addEventListener("resize", (e: any) => {
          const height = e.target?.videoHeight;
          const width = e.target?.videoWidth;
          if (height && width) {
            if (width > height) {
              videoFitPosition.current = "cover";
              videoElement.style.objectFit =
                videoFitPosition.current || "cover";
            } else {
              videoFitPosition.current = "contain";
              videoElement.style.objectFit =
                videoFitPosition.current || "contain";
            }
          }
        });
      }
    }
  };

  return (
    <>
      <div
        id={`${domId}`}
        className={`agora-rtc-video ${local ? "rotateY180deg" : ""} ${
          eventRoomState.gridView ? "gridview" : ""
        }`}
      ></div>
      <div className="stream-details">
        <div className="streamUserName" title={name}>
          {name}
        </div>
      </div>
      {!video && (
        <div className="">
          <VideocamOffIcon className="videodisableicon" />
        </div>
      )}

      {(eventRoomState.me.info.role === "host" ||
        eventRoomState.me.info.role === "residence") &&
        streamID !== liveStreams.mainStreams[0]?.streamID && (
          <div
            className="stream-controls"
            style={{
              left: "50%",
              top: "50%",
              right: "auto",
              backgroundColor: "#FFFFFF",
              borderRadius: 100,
              width: 32,
              height: 32,
              transform: 'translate(-50%, -50%)'
            }}
          >
            <button
              style={{
                backgroundColor: "transparent",
                border: 0,
                outline: "none",
                padding: 0,
                cursor: "pointer",
                width: 32,
                height: 32,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                handleSwitch && handleSwitch(streamID);
              }}
            >
              <img src={pinIcon} alt="" style={{ width: "80%" }} />
            </button>
          </div>
        )}

      {(eventRoomState.me.info.role == "guide" ||
        eventRoomState.me.info.role == "host") &&
        !local && (
          <div className="stream-controls">
            <RemoteAudioControl streamID={streamID} audio={audio} />
          </div>
        )}
    </>
  );
};

export default React.memo(VideoPlayer);
