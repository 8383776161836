import React from "react";
import { formatDate, formatTime } from "@/utils/helper";
import { eventRoomStore } from "@/stores/event";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "./Table.scss";

const VisitTable = (props: any) => {
  const history = useHistory();
  const { visits, itemsPerPage, totalevents, currentpage, setcurrentpage } =
    props;

  const joinVisit = (time: any, id: any) => {
    history.push(`/visit/${id}`);
    // if (eventRoomStore.canStartVisit(time)) {
    //   history.push(`/visit/${id}`);
    // } else {
    //   toast.error("You are not Allowed to Join before 10 minutes");
    // }
  };

  const pageHandler = (type: any) => {
    if (type == "forward") {
      if (Math.ceil(totalevents / itemsPerPage) > currentpage) {
        setcurrentpage(currentpage + 1);
      }
    }
    if (type == "backward") {
      if (currentpage > 1) {
        setcurrentpage(currentpage - 1);
      }
    }
  };
  return (
    <>
      <table className="guide-listing-table">
        <thead className="guide-listing-table-header">
          <tr>
            <th>Visit Name</th>
            <th>Visit Date/Time </th>
            <th></th>
          </tr>
        </thead>
        <tbody className="guide-listing-table-body">
          {visits.map((Visitdata: any, index: number) => {
            return (
              <tr key={index}>
                <td>{Visitdata.visit_name}</td>
                <td>
                  <div>
                    {formatDate(
                      eventRoomStore.convertTimeFromUTC(Visitdata.time_in_utc)
                    )}
                  </div>
                  <div>
                    {formatTime(
                      eventRoomStore.convertTimeFromUTC(Visitdata.time_in_utc)
                    )}
                  </div>
                </td>
                <td>
                  <button
                    onClick={() => {
                      joinVisit(Visitdata.time_in_utc, Visitdata.visit);
                    }}
                    className="guide-listing-button"
                  >
                    JOIN
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {totalevents > itemsPerPage && (
        <div className="pagination">
          {currentpage >= 2 && (
            <button
              onClick={() => {
                pageHandler("backward");
              }}
            >
              PREVIOUS
            </button>
          )}
          {Math.ceil(totalevents / itemsPerPage) > currentpage && (
            <button
              onClick={() => {
                pageHandler("forward");
              }}
            >
              NEXT
            </button>
          )}
        </div>
      )}
    </>
  );
};
export default VisitTable;
