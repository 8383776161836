import React, { useRef, useEffect, useState } from "react";
import { useEventRoomState } from "@/containers/root-container";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./local-audio-control.scss";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./local-audio-control.scss";
import PreviewStreamAudio from "@/components/preview-stream-audio/preview-stream-audio";
import { eventRoomStore } from "@/stores/event";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";

interface LocalAudioControlProps {
  stream?: any;
  audio: boolean;
}

const LocalAudioControl: React.FC<LocalAudioControlProps> = ({
  stream,
  audio,
}) => {
  const eventRoomState = useEventRoomState();
  const loadAudio = useRef<boolean>(false);
  const [showPreviewStream, setShowPreviewStream] = useState(false);
  const { initializeSocket, addSocketEvent } = useSocket();
  const volume = useRef({
    time: new Date().getTime(),
    level: 0,
  });
  const volumedetectionlevel = 0.5;
  // handle audio mute/unmute
  useEffect(() => {
    if (!stream) return;
    // prevent already muted audio
    if (!loadAudio.current) {
      if (!audio) {
        if (stream.audioTrack.enabled) {
          stream.audioTrack.setEnabled(false);
        }
      }
      loadAudio.current = true;
      return;
    }

    if (audio) {
      stream.audioTrack.setEnabled(true);
      sessionStorage.setItem("audioEnabled", "1");
    } else {
      stream.audioTrack.setEnabled(false);
      sessionStorage.setItem("audioEnabled", "0");
    }
  }, [stream, audio]);

  useEffect(() => {
    if (eventRoomState.me.info.role === "residence" || eventRoomState.me.info.role === "multiconnection") {
      // initializeSocket();
      addSocketEvent(ESocketEvents.IS_RESIDENCE_SILENT, (data) => {
        console.log("visit_id", data);
        eventRoomStore.updateSilentResidenceFeature(data.is_residence_silent);
      });
    }
  }, []);

  useEffect(() => {
    console.log(
      "eventRoomState.is_residence_silent inside UseEffect",
      eventRoomState.is_residence_silent
    );
    if (
      eventRoomState.me.info.role === "residence" ||
      eventRoomState.me.info.role === "multiconnection" &&
      eventRoomState.is_residence_silent
    ) {
      let setAudIntervalVol = setInterval(function () {
        console.log("volume Trigger", volume.current);
        let volumelevel =
          eventRoomState.rtc.localStream?.stream.audioTrack.getVolumeLevel();
        console.log(
          "diff Trigger",
          new Date().getTime() - volume.current?.time,
          volumelevel
        );
        if (volumelevel < volumedetectionlevel) {
          if (
            volume.current?.time &&
            new Date().getTime() - volume.current?.time > 300000 &&
            volume.current.level < volumedetectionlevel
          ) {
            volume.current = {
              ...volume.current,
              time: new Date().getTime(),
            };
            eventRoomStore.handleResidenceSilent(
              eventRoomState.me.info.guide.id
            );
          }
          volume.current = {
            ...volume.current,
            level: volumelevel,
          };
        } else {
          volume.current = {
            ...volume.current,
            level: volumelevel,
            time: new Date().getTime(),
          };
        }
      }, 10000);
      return () => {
        clearInterval(setAudIntervalVol);
      };
    }
  }, [eventRoomState.is_residence_silent]);

  // useEffect(() => {
  //   if (
  //     eventRoomState.me.info.role === "residence" &&
  //     eventRoomState.is_residence_silent
  //   ) {
  //     let setAudInterval: any;
  //     if (volume < 45) {
  //       setAudInterval = setInterval(function () {
  //         // eventRoomStore.handleResidenceSilent(eventRoomState.me.info.guide.id);
  //         console.log("Hello from eventRoomStore trigger");
  //       }, 10000);
  //       return () => {
  //         clearInterval(setAudInterval);
  //       };
  //     } else {
  //       clearTimeout(setAudInterval);
  //     }
  //   }
  // }, [eventRoomState.is_residence_silent]);

  const handleAudioClick = (evt: any) => {
    eventRoomStore.handleStreamControlClick("audio", audio);
  };

  const handlePreviewStream = () => {
    setShowPreviewStream((prevCheck) => !prevCheck);
  };

  return (
    <span className="audio stream-control">
      <span className="audio-button-container stream-control-container">
        <span
          onClick={handleAudioClick}
          className={
            audio && eventRoomState.me.info.role === "host"
              ? "audio-button-mic_icon stream-control-primary_icon audio-active"
              : "audio-button-mic_icon stream-control-primary_icon"
          }
        >
          {audio ? <MicIcon /> : <MicOffIcon />}
        </span>
        <span
          className={
            audio && eventRoomState.me.info.role === "host"
              ? "audio-button-arrow_icon stream-control-secondary_icon audio-active"
              : "audio-button-arrow_icon stream-control-secondary_icon"
          }
        >
          {showPreviewStream ? (
            <KeyboardArrowDownIcon onClick={handlePreviewStream} />
          ) : (
            <KeyboardArrowUpIcon onClick={handlePreviewStream} />
          )}
        </span>
      </span>
      {eventRoomState.me.info.role == "guide" ? (
        <p className="icons-tip">{audio ? "Mute" : "Unmute"}</p>
      ) : (
        <p className="icons-tip">{audio ? "Silence" : "Son"}</p>
      )}
      {showPreviewStream && (
        <PreviewStreamAudio handlePreviewStream={handlePreviewStream} />
      )}
    </span>
  );
};

export default React.memo(LocalAudioControl);
