import React, { useEffect, useState } from "react";
import FullScreen from "@/components/fullscreen/fullscreen";
import LocalAudioControl from "@/components/local-audio-control/local-audio-control";
import LocalVideoControl from "@/components/local-video-control/local-video-control";
import LocalChatControl from "@/components/local-chat-control/local-chat-control";
import ExitControl from "@/components/exit-control/exit-control";
import "./host-options.scss";
import { useMediaQuery } from "react-responsive";
import LocalVideoCameraFlipControl from "@/components/local-camera-flip-control";
import { isAndroid, isIOS } from "react-device-detect";
import SilentResidenceControl from "@/components/silent-residence-control/silent-residence-control";
import { useEventRoomState } from "@/containers/root-container";
import ParticipantsControl from "@/components/participants-control/participants-control";
import SettingsOptions from "@/components/settings-options/settings-options";
import GroupsIcon from "@mui/icons-material/Groups";
import ScreenShare from "@/components/share-screen/share-screen";

interface HostBottomOptionsProps {
  stream?: any;
  audio: boolean;
  video: boolean;
  fullScreenElemId?: string;
  handleChat?: any;
  handlePopUp?: any;
  handlesideStream?: any;
  toggleFullScreenConfirmation?: any;
  handelParticipantsBtn: any;
  handleSwtichStream: any;
}

const HostBottomOptions: React.FC<HostBottomOptionsProps> = ({
  stream,
  audio,
  video,
  fullScreenElemId,
  handleChat,
  handlePopUp,
  handlesideStream,
  toggleFullScreenConfirmation,
  handelParticipantsBtn,
  handleSwtichStream,
}) => {
  const eventRoomState = useEventRoomState();

  return (
    <>
      <div className="control-options">
        {/* {eventRoomState.me.info.role === "host" && <ParticipantsControl />} */}
        <LocalVideoControl stream={stream} video={video} />
        <LocalAudioControl stream={stream} audio={audio} />

        {/* {eventRoomState.me.info.role === "host" && <SilentResidenceControl />} */}
      </div>
      <div className="host-right-icons _right">
        {eventRoomState.is_technical_visit &&
          eventRoomState.me.info.role === "residence" && (
            <ScreenShare handleSwtichStream={handleSwtichStream} />
          )}
        {/* {(isAndroid || isIOS) && (
          <LocalVideoCameraFlipControl stream={stream} video={video} />
        )} */}
        <ExitControl handlePopUp={handlePopUp} />
        {eventRoomState.me.info.role === "host" && <SettingsOptions />}
        {/* <div className="control-options"> */}
        {/* </div> */}
        
        {eventRoomState.me.info.role !== "residence" &&
          <button onClick={handelParticipantsBtn} className="show-participants">
            <GroupsIcon />
          </button>
        }
        
        <LocalChatControl
          handleChat={handleChat}
        />
        <FullScreen
          elemId={fullScreenElemId}
          handlesideStream={handlesideStream}
          toggleFullScreenConfirmation={toggleFullScreenConfirmation}
        />
      </div>
    </>
  );
};

export default React.memo(HostBottomOptions);
