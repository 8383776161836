import React, { useState, useEffect, useRef } from "react";
import { CurrentTime } from "@/utils/helper";
import { eventRoomStore } from "@/stores/event";

export default function Timer(props: any) {
  const [currentTime, setcurrentTime] = useState(new Date());
  const [error, seterror]: any = useState({ text: "", color: "" });
  let timerType = props.timerType;

  const ErrorHandler = (val: any) => {
    seterror({ text: val.text, color: val.color });
  };

  const errorUpdater = (timeinUtc: any) => {
    const visit_date: any = eventRoomStore.convertTimeFromUTC(timeinUtc);
    const today: any = new Date();
    const differenceMinutes = (today.getTime() - visit_date.getTime()) / 60000;
    console.log("visit_diff", differenceMinutes);
    if (differenceMinutes < 5) {
      ErrorHandler({ text: "First 5 minutes of visit", color: "green" });
    } else if (differenceMinutes > 115) {
      ErrorHandler({ text: "Last 5 minutes of visit", color: "yellow" });
    } else {
      ErrorHandler({ text: "", color: "" });
    }
  };

  useEffect(() => {
    let timer: any;
    if (timerType == "event-error") {
      timer = setInterval(() => setcurrentTime(new Date()), 60000);
      errorUpdater(props.VisitDetails?.time_in_utc);
    } else {
      timer = setInterval(() => setcurrentTime(new Date()), 1000);
    }
    return function cleanup() {
      clearInterval(timer);
    };
  }, [currentTime]);

  return (
    <>
      {timerType == "event-error" ? (
        <span
          className={`event-error-details event-error-${error.color} event-error-background-${error.color}`}
        >
          {error.text}
        </span>
      ) : (
        <div className="timer">{CurrentTime(currentTime)}</div>
      )}
    </>
  );
}
