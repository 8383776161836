import React from "react";
import "./popup.scss";
import { useEventRoomState } from "@/containers/root-container";
import CloseIcon from "@mui/icons-material/Close";

export default function FullScreenConfirmation(props: any) {
  const eventRoomState = useEventRoomState();
  const getStyle = () => {
    return {
      width: "42%",
    };
  };

  const goToFullScreenMode = () => {
    const elem =
      (props.fullScreenElemId &&
        document.getElementById(props.fullScreenElemId)) ||
      document.documentElement;
    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } catch (err) {}
    props.handlesideStream(true);
    props.toggleFullScreenConfirmation();
  };

  return (
    <div id="myModal" className="modal">
      <div
        className={`modal-content `}
        style={getStyle()}
        onClick={() => {
          props.toggleFullScreenConfirmation();
        }}
      >
        {(eventRoomState.me.info.role == "residence" ||
          eventRoomState.me.info.role == "multiconnection") && (
          <>
            <div
              className="close"
              onClick={() => {
                props.toggleFullScreenConfirmation();
              }}
            >
              <CloseIcon
                onClick={() => {
                  props.toggleFullScreenConfirmation();
                }}
              />
            </div>
            <div className="s-card-inner">
              <h5>Pour une meilleure expérience, passez en plein écran</h5>
              <div className="s-card-wrapper">
                <div className="s-card-info">
                  <div className="s-btn">
                    <button
                      onClick={() => {
                        goToFullScreenMode();
                        props.toggleFullScreenConfirmation();
                      }}
                      className="s-btn-quit"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
