import React, { useEffect, useState } from "react";
import FullScreen from "@/components/fullscreen/fullscreen";
import ExitControl from "@/components/exit-control/exit-control";
import "../host-options/host-options.scss";

interface HostBottomOptionsProps {
  fullScreenElemId?: string;
  handlePopUp?: any;
  handlesideStream?: any;
  toggleFullScreenConfirmation?: any;
}

const InviteeBottomOptions: React.FC<HostBottomOptionsProps> = ({
  fullScreenElemId,
  handlePopUp,
  handlesideStream,
  toggleFullScreenConfirmation,
}) => {
  return (
    <>
      <div className="control-options">
        <ExitControl handlePopUp={handlePopUp} />
      </div>
      <div className="host-right-icons _right">
        <FullScreen
          elemId={fullScreenElemId}
          handlesideStream={handlesideStream}
          toggleFullScreenConfirmation={toggleFullScreenConfirmation}
        />
      </div>
    </>
  );
};

export default React.memo(InviteeBottomOptions);
