import React, { useEffect, useRef, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './RemoveParticipants.scss'
import { useHistory } from 'react-router-dom';
import { ChannelParams, ChatCmdType } from '@/utils/agora-rtm-client';
import { eventRoomStore } from '@/stores/event';
import pinIcon from "@/assets/pin-icon.png";
;
interface RemoveParticipantsProps {
    streamID: any;
    liveStreams: any
    handleSwtichStream?: (streamID: string) => void;
}

const RemoveParticipants: React.FC<RemoveParticipantsProps> = ({ streamID, liveStreams, handleSwtichStream }) => {
    const [showMoreActions, setShowMoreActions] = useState(false)
    const [isPinned, setIsPinned] = useState<boolean>(false)
    const history = useHistory();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handelMoreAction = () => {
        setShowMoreActions(!showMoreActions)
    }

    const handleRemoveParticipant = async () => {
        const msgBody: ChannelParams = {
            type: ChatCmdType.remoteUsersRemove,
            msgData: {}
        };
        await eventRoomStore.sendPrivateMessage(streamID, msgBody)
        setShowMoreActions(false)
    };

    const handlePinStream = () => {
        if (liveStreams.mainStreams[0]?.streamID === streamID) {
            handleSwtichStream && handleSwtichStream("");
            setIsPinned(!isPinned);
        } else {
            handleSwtichStream && handleSwtichStream(streamID);
            setIsPinned(!isPinned);
        }
        setShowMoreActions(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowMoreActions(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div>
            <div className='more-icon' onClick={handelMoreAction}>
                <MoreVertIcon />
            </div>
            {showMoreActions &&
                <div className='actions-list' ref={dropdownRef}>
                    <ul className="action-list-data">
                        {eventRoomStore._state.me.info.role === 'host' &&
                            <li onClick={handleRemoveParticipant}>
                                Remove Participants
                            </li>
                        }
                        {/* {streamID !== liveStreams.mainStreams[0]?.streamID && ( */}
                            <li
                                onClick={handlePinStream}
                            >
                                {!isPinned ? 'Pin Stream' : 'Unpin Stream'}
                            </li>
                        
                    </ul>
                </div>
            }
        </div>
    )
}
export default RemoveParticipants