import React, { useRef, useEffect, useState } from "react";
import { eventRoomStore } from "@/stores/event";
import "./remote-video-control.scss";

import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import { useEventRoomState } from "@/containers/root-container";

interface RemoteVideoControlProps {
  streamID?: any;
  video: boolean;
}

const RemoteVideoControl: React.FC<RemoteVideoControlProps> = ({
  streamID,
  video,
}) => {
  const eventRoomState = useEventRoomState();
  const handleVideoClick = (evt: any) => {
    evt.stopPropagation();
    if (video) {
      eventRoomStore.handleRemoteVideo(streamID, "mute");
    } else {
      eventRoomStore.handleRemoteVideo(streamID, "unmute");
    }
  };

  return (
    <span
      className="video stream-control controller"
      onClick={handleVideoClick}
    >
      <span>
        {video ? <VideocamIcon /> : <VideocamOffIcon />}
        {eventRoomState.me.info.role == "guide" ? (
          <p className="icons-tip">
            {video ? "Disable Video" : "Enable Video"}
          </p>
        ) : (
          <p className="icons-tip">
            {video ? "Désactiver video" : "Activer video"}
          </p>
        )}
      </span>
    </span>
  );
};

export default React.memo(RemoteVideoControl);
