import React, { useEffect, useState } from "react";
import { globalStore } from "@/stores/global";
import { eventRoomStore } from "@/stores/event";
import { useHistory, useParams } from "react-router-dom";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import Welcome from "../welcome/Welcome";
import GuideWelcome from "../Guide/Welcome";
import Cookies from "js-cookie";
import { updateUserToken } from "@/utils/api/services/auth.services";

type ParamTypes = {
  visit: any;
  [key: string]: any;
};

function Visit() {
  const [isLoading, setIsLoading] = useState(true);
  const [visitDetails, setVisitDetails] = useState<any>();

  const param = useParams<ParamTypes>();
  const visit = param?.visit;

  useEffect(() => {
    const getVisitDetails = async () => {
      const visitDetails = await eventRoomStore.fetchVisitDetails(visit);
      if (visitDetails?.status == 423) {
        window.location.href = "/error/invalid";
      } else if (visitDetails.status == 200) {
        const details = visitDetails.visit as any;
        if (details && details.user_role != "guide" && Cookies.get("token")) {
          /* Guide can't join residence/host visit */
          window.location.href = "/error/access-denied";
        }
        setVisitDetails(details);
        setIsLoading(false);
      } else {
        if (Cookies.get("token")) {
          if (visitDetails.status == 401) {
            //If it's guide and if token is expired
            await updateUserToken(Cookies.get("token"));
            getVisitDetails();
          } else if (visitDetails.status == 400) {
            window.location.href = "/error/access-denied";
          } else {
            eventRoomStore.logout();
          }
        } else {
          window.location.href = "/404";
        }
      }
    };
    if (visit) {
      setIsLoading(true);
      getVisitDetails();
    } else {
      window.location.href = "/404";
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <WelcomeLoader />
      ) : (
        <>
          {visitDetails.user_role == "guide" ? (
            <GuideWelcome visit={visitDetails} visit_id={visit} />
          ) : (
            <Welcome visit={visitDetails} visit_id={visit} />
          )}
        </>
      )}
    </>
  );
}
export default Visit;
