import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";

const NoiseCancellation: React.FC = () => {
  const eventRoomState = useEventRoomState();
  const { initializeSocket, emitEvent, removeSocketEvent } = useSocket();
  const [is_noise_cancellation_feature, setIs_noise_cancellation_feature] =
    useState(eventRoomState.is_noise_cancellation_on);
  const NoiseCancellationfeaturehandler = () => {
    setIs_noise_cancellation_feature(!is_noise_cancellation_feature);
    eventRoomStore.updateNoiseCancellation(!is_noise_cancellation_feature);
    // initializeSocket();
    emitEvent(ESocketEvents.IS_NOISE_CANCELLATION_ON, {
      visit_id: eventRoomState.me.channelName,
      is_noise_cancellation_on: !is_noise_cancellation_feature,
    });
    return () => {
      removeSocketEvent(ESocketEvents.IS_NOISE_CANCELLATION_ON);
    };
  };

  useEffect(() => {
    setIs_noise_cancellation_feature(eventRoomState.is_noise_cancellation_on);
  }, [eventRoomState.is_noise_cancellation_on]);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            className="switch-mode-settings"
            checked={is_noise_cancellation_feature}
            onChange={NoiseCancellationfeaturehandler}
            name="Silent"
          />
        }
        label="Surrounding noise off"
      />
    </>
  );
};
export default NoiseCancellation;
