import React, { useEffect } from "react";
import "./popup.scss";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import CloseIcon from "@mui/icons-material/Close";

export default function ConfirmationPopUp(props: any) {
  const { initializeSocket, emitEvent } = useSocket();
  const eventRoomState = useEventRoomState();
  const getStyle = () => {
    return {
      width: "42%",
    };
  };

  useEffect(() => {
    initializeSocket();
  }, []);

  const exit = (exitType: any) => {
    if (eventRoomState.me.info.role == "host" && exitType == "quit") {
      emitEvent(ESocketEvents.EndVisit, {
        visit_id: eventRoomState.me.channelName,
      });
    }
    let endScreenURL = "/end";
    eventRoomStore.handleMainExit(eventRoomState.me.info.role, endScreenURL);
  };

  const handlemeetClose = (exitType: any) => {
    eventRoomStore.ExitFullScreenMode();
    eventRoomStore.exitAll();
    exit(exitType);
  };

  return (
    <div
      id="myModal"
      className="modal"
      onClick={() => {
        props.handlePopUpClose();
        props.toggleConfirmationPopup(false);
      }}
    >
      <div className={`modal-content `} style={getStyle()}>
        <>
          <div
            className="close"
            onClick={() => {
              props.handlePopUpClose();
              props.toggleConfirmationPopup(false);
            }}
          >
            <CloseIcon
              onClick={() => {
                props.handlePopUpClose();
                props.toggleConfirmationPopup(false);
              }}
            />
          </div>
          {eventRoomState.me.info.role == "host" && (
            <div className="s-card-inner">
              <h5>Êtes-vous sûr de vouloir fermer la session ?</h5>
              <div className="s-card-wrapper">
                <div className="s-card-info">
                  <div className="s-btn">
                    <button
                      className="s-btn-quit"
                      onClick={() => handlemeetClose("quit")}
                    >
                      Quitter et fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
