import React from "react";
import WelcomeLoader from "@/pages/WelcomeLoader/WelcomeLoader";
import "./networkpopup.scss";

const NetworkPopup = () => {
  return (
    <>
      <div id="myModal" className="modal network-loader-position">
        <div className="network-loader">
          <div className="network-loader-text">CHECKING FOR NETWORK</div>
          <div>
            <WelcomeLoader />
          </div>
        </div>
      </div>
    </>
  );
};
export default NetworkPopup;
