import React, { useRef, useEffect, useState } from "react";
import "../../video-player/video-player.scss";
import { useEventRoomState } from "@/containers/root-container";
import useStream from "@/hooks/use-stream";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import { AgoraStreamSpec } from "@/utils/agora-rtc-client";
import { AgoraWebClient } from "@/utils/agora-web-client";
import { all } from "axios";

interface VideoPlayerProps {
  eventRoomStoreLocal: any;
  domId?: string;
  id?: string;
  streamID: number;
  preview?: boolean;
  stream?: any;
  role?: string;
  audio: boolean;
  video: boolean;
  className?: string;
  showProfile?: boolean;
  local?: boolean;
  handleClick?: (type: string, streamID: number, uid: string) => Promise<any>;
  close?: boolean;
  handleClose?: (uid: string, streamID: number) => void;
  autoplay?: boolean;
  onAutoPlayFailed?: (stream: any) => void;
  mainStream?: boolean;
  name: any;
  mainSt?: any;
  index?: any;
  handleSwitch?: (streamID: number) => void;
  liveStreams?: any;
}

const AdminVideoPlayer: React.FC<VideoPlayerProps> = ({
  eventRoomStoreLocal,
  preview,
  role,
  stream,
  className,
  domId,
  streamID,
  audio,
  id,
  video,
  handleClick,
  local,
  handleClose,
  close,
  autoplay,
  onAutoPlayFailed,
  mainStream,
  name,
  mainSt,
  index,
  handleSwitch,
  liveStreams = {},
}) => {
  const eventRoomState = eventRoomStoreLocal.state;
  const webClient = eventRoomStoreLocal.rtcClient as AgoraWebClient;
  const lockPlay = useRef<boolean>(false);
  const lockPlayAudio = useRef<boolean>(false);
  const { allStreams, adminStream, guideStream, remoteStreams } =
    useStream(eventRoomStoreLocal);
  const [VolumeStatus, setVolumeStatus] = useState(false);
  const [SpeakingStatus, setSpeakingStatus] = useState(false);
  const videoFitPosition = useRef<string>("");
  useEffect(() => {
    //if (!streamID || (stream.videoTrack==null && stream.audioTrack==null) || (stream.videoTrack!=null && stream.videoTrack.isPlaying) || (stream.audioTrack!=null && stream.audioTrack.isPlaying)) return;
    if (!streamID) return;
    playStream();
    return () => {
      if (stream.videoTrack && stream.videoTrack.isPlaying && stream) {
        try {
          if (local) {
            stream.videoTrack.setEnabled(false); //Disable camera access when a user mutes their local video
          }
          stream.videoTrack.stop();
          lockPlay.current = false;
        } catch (e) {
          console.warn("Stop streaming Error", e);
        }
      }
    };
  }, [streamID, video]);

  useEffect(() => {
    if (!streamID) return;
    if (VolumeStatus) {
      playStreamAudio(remoteStreams);
    } else {
      stopAllRemoteAudioTracks(remoteStreams);
    }
    // playStreamAudio();

    return () => {
      if (stream.audioTrack && stream.audioTrack.isPlaying && stream) {
        try {
          stream.audioTrack.stop();
          lockPlayAudio.current = false;
        } catch (e) {
          console.warn("Stop streaming Error", e);
        }
      }
    };
  }, [streamID, audio]);

  const playStreamAudio = (streams: any[]) => {
    lockPlayAudio.current = true;
    streams.forEach((stream: any) => {
      if (stream.stream.audioTrack && !stream.stream.audioTrack.isPlaying) {
        if (!stream.local && sessionStorage.getItem("speakerId")) {
          stream.stream.audioTrack.setPlaybackDevice(sessionStorage.getItem("speakerId"));
        }
        stream.stream.audioTrack.play();
      }
    });  
    lockPlayAudio.current = false;
  };

  const stopAllRemoteAudioTracks = (streams: any[]) => {
    streams.forEach((stream: any) => {
      if (stream.stream.audioTrack && stream.stream.audioTrack.isPlaying) {
        try {
          stream.stream.audioTrack.stop();
        } catch (e) {
          console.warn("Stop remote audio track Error", e);
        }
      }
    });
  };

  const playStream = async () => {
    lockPlay.current = true;
    let isGuideStream = false;
    if (eventRoomState.me.info.role == "guide" && local) {
      isGuideStream = true;
    } else if (eventRoomState.me.info.role != "guide") {
      if (guideStream && guideStream?.streamID == streamID) {
        isGuideStream = true;
      }
    }

    const fit =
      isGuideStream && videoFitPosition.current
        ? videoFitPosition.current
        : isGuideStream && !videoFitPosition.current
        ? "contain"
        : "cover";

    if (
      typeof stream.videoTrack != "undefined" &&
      stream.videoTrack != null &&
      video
    ) {
      if (local) {
        await stream.videoTrack.setEnabled(true); //Enable camera access when a user mutes their local video
      }
      stream.videoTrack.play(`${streamID}`, { fit: fit }, (err: any) => {
        lockPlay.current = false;
        console.warn(
          "[video-player] ",
          JSON.stringify(err),
          id,
          stream.videoTrack.isPaused(),
          stream.videoTrack.isPlaying(),
          " isLocal: ",
          local
        );
      });

      if (isGuideStream) {
        const divElement = document.getElementById(`${streamID}`);
        const videoElement = divElement?.querySelector("video");

        videoElement?.addEventListener("resize", (e: any) => {
          const height = e.target?.videoHeight;
          const width = e.target?.videoWidth;
          if (height && width) {
            if (width > height) {
              videoFitPosition.current = "cover";
              videoElement.style.objectFit =
                videoFitPosition.current || "cover";
            } else {
              videoFitPosition.current = "contain";
              videoElement.style.objectFit =
                videoFitPosition.current || "contain";
            }
          }
        });
      }
    }
  };

  const publish = async () => {
    webClient.exit();
    let streamSpec: AgoraStreamSpec = {
      streamID: eventRoomStoreLocal.state.me.info.uid,
      video: false,
      audio: true,
      screen: false,
      optimizationMode: "motion",
      isInjectedStream: false,
    };
    await webClient.rtc.setClientRole("host");
    await webClient.rtc.join(
      eventRoomStoreLocal.state.appID,
      eventRoomStoreLocal.state.rtcToken,
      eventRoomStoreLocal.state.me.channelName,
      eventRoomStoreLocal.state.me.info.uid
    );
    await Promise.all([webClient.publishLocalStream(streamSpec)])
      .then((res: any[]) => {
        console.log("[agora-web] any: ", res[0], res[1]);
        console.log("[agora-web] publish local stream");
      })
      .catch(console.warn)
      .finally(() => {
        // globalStore.stopLoading();
      });
    console.log("webClient in publish", webClient);
  };

  console.log(eventRoomState.me.info.host.name, 'host-details')

  const unpublish = () => {
    webClient.exit();
    webClient.rtc.setClientRole("audience");
    webClient.rtc.join(
      eventRoomStoreLocal.state.appID,
      eventRoomStoreLocal.state.rtcToken,
      eventRoomStoreLocal.state.me.channelName,
      eventRoomStoreLocal.state.me.info.uid
    );
    console.log("webClient", webClient);
  };

  return (
    <>
      <div
        id={`${domId}`}
        className={`agora-rtc-video ${
          local ? "rotateY180deg no-video-background" : ""
        } ${eventRoomState.gridView ? "gridview" : ""}`}
      ></div>
      <div className="event-audio-listner">
        {VolumeStatus ? (
          <span className="event-right">
            <p className="icons-tip">Listen</p>
            <VolumeUpIcon
              htmlColor={"#008000"}
              onClick={() => {
                setVolumeStatus(false);
                stopAllRemoteAudioTracks(remoteStreams);
              }}
            />
          </span>
        ) : (
          <span className="event-right">
            <p className="icons-tip">Listen</p>
            <VolumeOffIcon
              htmlColor={"#fff"}
              onClick={() => {
                setVolumeStatus(true);
                playStreamAudio(remoteStreams);
              }}
            />
          </span>
        )}
      </div>
      <div className="event-audio-speaker">
        {SpeakingStatus ? (
          <span className="event-right">
            <p className="icons-tip">Speak</p>
            <MicIcon
              htmlColor={"#008000"}
              onClick={() => {
                // AdminHandler("mute");
                unpublish();
                setSpeakingStatus(false);
              }}
            />
          </span>
        ) : (
          <span className="event-right">
            <p className="icons-tip">Speak</p>
            <MicOffIcon
              htmlColor={"#fff"}
              onClick={() => {
                // AdminHandler("unmute");
                publish();
                setSpeakingStatus(true);
              }}
            />
          </span>
        )}
      </div>
      <div className="events-info">
        <div className="event-joined-info">
          <span className="event-title">{eventRoomState.me.info.host.name} {name ? name : ""}</span>
          <span className="event-users">
            {role ? role.charAt(0).toUpperCase() + role.slice(1) : ""}
          </span>
        </div>
      </div>
      {!video && (
        <div className="">
          <VideocamOffIcon className="videodisableicon" />
        </div>
      )}
    </>
  );
};

export default AdminVideoPlayer;
