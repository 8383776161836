import React, { useRef, useState } from "react";
import "./PreCallScreen.scss";
import { eventRoomStore } from "@/stores/event";
import WelcomeLoader from "../WelcomeLoader/WelcomeLoader";
import Logo from "@/assets/logo.png";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import { useEventRoomState } from "@/containers/root-container";
import { toast } from "react-toastify";

interface PrecallScreenProps {
  JoinHandle?: any;
}

const PreCallScreenInvitee: React.FC<PrecallScreenProps> = ({ JoinHandle }) => {
  const [requestText, setRequestText] = useState("Attente de connexion");
  const isLoader = useRef<boolean>(false);
  const { emitEvent } = useSocket();
  const eventRoomState = useEventRoomState();

  const setLocalStreamDevices = () => {
    JoinHandle();
  };

  const ResidenceJoinHandler = () => {
    isLoader.current = true;
    emitEvent(ESocketEvents.ACCESS_WAIT, {
      visit_id: eventRoomState.me.channelName,
      user_id: eventRoomState.me.info.uid,
      user_name: eventRoomState.me.info.name,
      user_role: eventRoomState.me.info.role,
    });
    // sessionStorage.setItem("userId", eventRoomState.me.info.uid);
    // sessionStorage.setItem("userole", eventRoomState.me.info.role);
    // sessionStorage.setItem("visit_id", eventRoomState.me.channelName);
    // sessionStorage.setItem("username", eventRoomState.me.info.name);
    setRequestText("Veuillez patienter un instant");
  };

  return (
    <>
      <div className="precall-screen">
        <div className="precall-screen-logo">
          <img src={Logo} />
        </div>
        <div
          className="precall-screen-content"
          style={{ flexDirection: "column", justifyContent: "unset" }}
        >
          <div className="precall-screen-options">
            <div
              className="precall-screen-text"
              style={{
                fontSize:
                  eventRoomStore._state.me.info.role === "invitee"
                    ? "1.8rem"
                    : "1.8rem",
              }}
            >
              {requestText}
            </div>
            {eventRoomStore._state.me.info.role === "invitee" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <p style={{ width: "100%", textAlign: "center" }}>
                  A titre d’étude, vous allez rejoindre un voyage culturel à
                  distance Travel Me où vous pourrez simplement visualiser (sans
                  interagir) la visite en direct.
                </p>
                <p style={{ width: "100%", textAlign: "center" }}>
                  Pour respecter les droits à l'image et la confidentialité des
                  participants, nous vous rappelons que tout enregistrement ou
                  diffusion est interdite.
                </p>
                <div
                  style={{
                    position: "relative",
                    top: "50%",
                    left: "0",
                    right: "0",
                  }}
                >
                  {isLoader.current ? (
                    <WelcomeLoader />
                  ) : (
                    <button
                      onClick={ResidenceJoinHandler}
                      className="precall-screen-button"
                    >
                      Rejoindre la visite
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default PreCallScreenInvitee;
