import React from "react";
import "./local-chat-control.scss";
import ChatIcon from "@mui/icons-material/Chat";
import { eventRoomStore } from "@/stores/event";

export default function LocalChatControl(props: any) {
  const { handleChat } = props;

  const handleChatClick = () => {
    handleChat();
    eventRoomStore._state.isMessageSent = false;
  };
  // useEffect(() => {
  //   // Update showChat based on the role
  //   if (eventRoomStore._state.me.info.role == "host") {
  //     handleChatClick();
  //   }
  // }, []);

  return (
    <>
      <span className="chat-control" onClick={handleChatClick}>
        {eventRoomStore._state.isMessageSent && (
          <span className="chat-control-icon-new"></span>
        )}
        <div className="chat-control-icon">
          <span className="chat-control-position">
            <ChatIcon />
          </span>
        </div>
        <p className="icons-tip">Chat</p>
      </span>
    </>
  );
}
