import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useSocket from "@/hooks/useSocket";
import { ESocketEvents } from "@/utils/api/sockets.enum";
import { useEventRoomState } from "@/containers/root-container";
import { eventRoomStore } from "@/stores/event";

const SilentResidenceControl: React.FC = () => {
  const eventRoomState = useEventRoomState();
  const { initializeSocket, emitEvent, removeSocketEvent } = useSocket();
  const [is_residence_silent_feature, setIs_residence_silent_feature] =
    useState(eventRoomState.is_residence_silent);

  const Residencesilentfeaturehandler = () => {
    setIs_residence_silent_feature(!is_residence_silent_feature);
    eventRoomStore.updateSilentResidenceFeature(!is_residence_silent_feature);
    // initializeSocket();
    emitEvent(ESocketEvents.IS_RESIDENCE_SILENT, {
      visit_id: eventRoomState.me.channelName,
      is_residence_silent: !is_residence_silent_feature,
    });
    return () => {
      removeSocketEvent(ESocketEvents.IS_RESIDENCE_SILENT);
    };
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            className="switch-mode-settings"
            checked={is_residence_silent_feature}
            onChange={Residencesilentfeaturehandler}
            name="Silent"
          />
        }
        label="Interaction alert"
      />
    </>
  );
};
export default SilentResidenceControl;
